<template>
  <nav class="navbar  navbar-expand-xl">
    <div class="container">
      <a class="navbar-brand" href="#">
        <img src="@/assets/images/logo.png" alt="logo" class="img-fluid">
      </a>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav m-auto">
          <li class="nav-item">
            <a class="nav-link" @click.prevent="scrollTo('#services')" href="#services">Послуги</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" @click.prevent="scrollTo('#portfolio')" href="#portfolio">Портфоліо</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" @click.prevent="scrollTo('#reviews')" href="#reviews">Відгуки</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" @click.prevent="scrollTo('#contacts')" href="#contacts">Контакти</a>
          </li>
        </ul>
        <div class="info">
          <div class="phone">
            <a href="tel:+380952355556">+38 (095) 235-55-56</a>
          </div>
          <div class="buttons">
            <a href="#form" @click.prevent="showApplication" class="btn btn-red">Залишити заявку</a>
          </div>
        </div>
        <div class="lang">
          <a href="/en">En</a>
          <span>/</span>
          <span class="active">Ua</span>
          <span>/</span>
          <a href="/ru">Ru</a>
        </div>
      </div>
      <button @click="showMenu" class="navbar-toggler" type="button">
        <span></span>
        <span></span>
        <span></span>
      </button>
    </div>
  </nav>
  <div class="menu">
    <div class="menu__overlay"></div>
    <div class="menu__container">
      <div class="lang">
          <a href="/en">En</a>
          <span>/</span>
          <span class="active">Ua</span>
          <span>/</span>
          <a href="/ru">Ru</a>
      </div>
      <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link" @click.prevent="showMenu('#services')" href="#services">Послуги</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" @click.prevent="showMenu('#portfolio')" href="#portfolio">Портфоліо</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" @click.prevent="showMenu('#reviews')" href="#reviews">Відгуки</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" @click.prevent="showMenu('#contacts')" href="#contacts">Контакти</a>
        </li>
      </ul>
      <div class="info">
        <div class="phone">
          <a href="tel:+380952355556">+38 (095) 235-55-56</a>
        </div>
        <div class="buttons">
          <a href="#" @click.prevent="showApplication" class="btn btn-red">Залишити заявку</a>
        </div>
      </div>
    </div>
  </div>
  <section class="main">
    <div class="container">
      <div class="row">
        <div class="col-xxl-5 col-lg-6 py-3 order-lg-1 fromBottom">
          <img src="@/assets/images/main.png" alt="image" class="img-fluid mx-auto d-block">
        </div>
        <div class="col-xxl-5 offset-xxl-1 col-lg-6 py-3 fromBottom">
          <h1>Ваша потужна IT команда</h1>
          <p class="subtitle">Правильных фахівців у вирішенні бизнес - задач будь-якої складності.</p>
          <p>Більше 9 років розробляємо веб-додатки, великі системи обліку та автоматизації бізнесу.
            Працюємо з усіма сучасними технологіями, включаючи блокчейн із подальшим обслуговуванням та веденням Вашого проекту.</p>
          <div class="buttons">
            <a href="#contacts" @click.prevent="scrollTo('#contacts')" class="btn">Зв'язатись з нами</a>
            <a href="#services" @click.prevent="scrollTo('#services')" class="btn btn-red">Дізнатись більше</a>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="advantage">
    <div class="container">
      <div class="row align-items-stretch">
        <div class="col-md-4 advantage-item fromBottom">
          <div class="card">
            <div class="card-body">
              <div class="card-title">9</div>
              <div class="card-text">
                На ринку, як команда, ми більше 9 років
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 advantage-item fromBottom">
          <div class="card">
            <div class="card-body">
              <div class="card-title">12</div>
              <div class="card-text">
                Мінімальний досвід фахівців 12 років
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 advantage-item fromBottom">
          <div class="card">
            <div class="card-body">
              <div class="card-title">60+</div>
              <div class="card-text">
                Успішних проектів якими пишаємося
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="offer" id="services">
    <div class="container">
      <div class="row">
        <div class="col-xxl-11 offset-xxl-1 fromBottom">
          <h2>Що ми пропонуємо?</h2>
        </div>
        <div class="col-xxl-5 offset-xxl-1 col-lg-6 py-3 fromBottom">
          <div class="image__static">
            <!--            <div class="image__rotate">-->
            <!--              <img src="@/assets/images/logo/logo1.svg" class="logo logo-1" alt="logo">-->
            <!--              <img src="@/assets/images/logo/logo2.svg" class="logo logo-2" alt="logo">-->
            <!--              <img src="@/assets/images/logo/logo3.svg" class="logo logo-3" alt="logo">-->
            <!--              <img src="@/assets/images/logo/logo4.svg" class="logo logo-4" alt="logo">-->
            <!--              <img src="@/assets/images/logo/logo5.svg" class="logo logo-5" alt="logo">-->
            <!--            </div>-->
            <img src="@/assets/images/offer.png" alt="image" class="img-fluid  mx-auto d-block">
          </div>
        </div>
        <div class="col-xxl-5 col-lg-6 py-3">
          <div class="card fromBottom">
            <div class="card-body">
              <div class="card-title">
                <img src="@/assets/images/icon/integration.svg" alt="icon" class="img-fluid">
                <span>Інтеграції з блокчейн</span>
              </div>
              <div class="card-text">Реалізуємо різні проекти із використанням технології Блокчейн (EOS, NEM, TRON, Ethereum).
              </div>
            </div>
          </div>
          <div class="card fromBottom">
            <div class="card-body">
              <div class="card-title">
                <img src="@/assets/images/icon/connection.svg" alt="icon" class="img-fluid">
                <span>Веб рішення для бізнесу</span></div>
              <div class="card-text">Розробимо веб-платформу будь-якої складності під Ваш бізнес із інтеграцією різних систем. Виконаємо доопрацювання існуючої системи.
              </div>
            </div>
          </div>
          <div class="card fromBottom">
            <div class="card-body">
              <div class="card-title">
                <img src="@/assets/images/icon/website.svg" alt="icon" class="img-fluid">
                <span>Сайти та інтернет магазини</span>
              </div>
              <div class="card-text">Розробимо корпоративный сайт або інтернет-магазин, виконаємо всі необхідні інтеграції.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="better">
    <div class="container">
      <div class="row">
        <div class="col-xxl-11 offset-xxl-1 fromBottom">
          <h2>Чим ми кращі за інших?</h2>
        </div>
        <div class="col-xxl-5 col-lg-6 py-3 order-lg-1 fromBottom">
          <img src="@/assets/images/better.png" alt="image" class="img-fluid mx-auto d-block">
        </div>
        <div class="col-xxl-5 offset-xxl-1 col-lg-6 py-3 fromBottom">
          <p>Передові технології та згуртована команда.</p>
          <p>Величезний досвід автоматизації процесів у великих і дрібних компаніях.</p>
          <p>Достатня експертиза на вирішення завдань різної складності під ключ.</p>
          <p>Ми допоможемо Вам описати вимоги та повністю виконаємо завдання: розробимо, впровадимо, навчимо персонал та забезпечимо підтримку.</p>
          <p class="fw-bold">ЗАЛИШТЕ ЗАЯВКУ та отримайте БЕЗКОШТОВНУ кваліфіковану консультацію наших експертів.</p>
          <div class="buttons">
            <a href="#" @click.prevent="showConsultation" class="btn btn-red">Отримати консультацію</a>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="how-work">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-xxl-11 offset-xxl-1 fromBottom">
          <h2>Як ми працюємо?</h2>
        </div>
        <div class="col-xxl-5 offset-xxl-1 col-lg-6 py-3 fromBottom">
          <img src="@/assets/images/how-work.png" alt="image" class="img-fluid mx-auto d-block">
        </div>
        <div class="col-xxl-5 col-lg-6 py-3">
          <div class="how-work__item fromBottom">
            <h3>
              <img src="@/assets/images/icon/mind.svg" alt="icon">
              <span>Экспертиза</span>
            </h3>
            <p>Ми допоможемо правильно спланувати проект для максимальної ефективності робіт та бюджетування. Ви відчуватимете впевненість та комфорт у міру просування проекту.</p>
          </div>
          <div class="how-work__item fromBottom">
            <h3>
              <img src="@/assets/images/icon/rocket.svg" alt="icon">
              <span>Швидкість</span>
            </h3>
            <p>Дотримання концепції CI/CD дозволяє нам показувати видимі результати на всіх етапах проекту. Ви можете розпочати використання продукту в найкоротший термін.</p>
          </div>
          <div class="how-work__item fromBottom">
            <h3>
              <img src="@/assets/images/icon/thumb-up.svg" alt="icon">
              <span>Якість</span>
            </h3>
            <p>Ретельне тестування на всіх етапах розробки, а також постійний моніторинг роботи продукту дозволяє мінімізувати ризики збоїв та збільшити швидкість усунення несправностей.</p>
          </div>
          <div class="how-work__item fromBottom">
            <h3>
              <img src="@/assets/images/icon/technologies.svg" alt="icon">
              <span>Технологічність</span>
            </h3>
            <p>Ми постійно відстежуємо технології, що швидко розвиваються, і використовуємо їх у нашій роботі.</p>
          </div>
          <div class="how-work__item fromBottom">
            <h3>
              <img src="@/assets/images/icon/shield.svg" alt="icon">
              <span>Безпека</span>
            </h3>
            <p>Використання широкого інструментарію захисту даних, а також постійне відстеження загроз безпеки дасть Вам впевненість у збереженні даних.</p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="what-do" id="portfolio">
    <div class="container">
      <h2 class="fromBottom">Що ми зробили?</h2>
      <!-- Slider main container -->
      <div class="swiper-container fromBottom">
        <!-- If we need navigation buttons -->
        <div class="swiper-button">
          <div class="swiper-button-line"></div>
          <div class="swiper-button-prevc">
            <img src="@/assets/images/arrowLeft.svg" alt="icon">
          </div>
          <div class="swiper-button-nextc">
            <img src="@/assets/images/arrowRight.svg" alt="icon">
          </div>
        </div>

        <!-- Additional required wrapper -->
        <div class="swiper-wrapper">
          <!-- Slides -->
          <div class="swiper-slide">
            <div class="card mb-3">
              <div class="card-body">
                <div class="row align-items-center my-3">
                  <div class="col-lg-5 border-end">
                    <div class="info mx-3">
                      <div class="info__title">FreeTeam</div>
                      <div class="info__description">FreeTeam - онлайн школа покеру, система бекінгових фондів</div>
                      <div class="info__task">
                        <div class="info__task-title">
                          <img src="@/assets/images/icon/portfolio-task.svg" alt="icon"><span>Завдання проекту</span>
                        </div>
                        <div class="info__task-description">
                          Проектування та реалізація онлайн платформи для повного циклу навчання, від автоматичного аналізу поданої анкети учнем, до врахування успішності відіграної серії турнірів у процесі та після навчання.
                        </div>
                      </div>
                      <div class="info__what">
                        <div class="info__what-title">
                          <img src="@/assets/images/icon/portfolio-what.svg" alt="icon"><span>Що зроблено?</span>
                        </div>
                        <div class="info__what-description">
                            Запущено платформу онлайн навчання. Модульна реалізація.
                            Основні модулі - прийом та обробка заявок, система проведення онлайн тренувань,
                            облікова система Фонди (повна автоматизація процесу взаєморозрахунків між гравцями та бекінговими фондами),
                            форум, магазин, новини, елементи гейміфікації із внутрішньою валютою, можлива інтеграція з блокчейн.
                        </div>
                      </div>
                      <div class="info__link">
                        <img src="@/assets/images/icon/portfolio-link.svg" alt="icon">
                        <a href="https://freeteamclub.com/" target="_blank">freeteamclub.com</a>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-7 image">
                    <img src="@/assets/images/portfolio-freeteam.png" alt="image">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="swiper-slide">
            <div class="card mb-3">
              <div class="card-body">
                <div class="row align-items-center my-3">
                  <div class="col-lg-5 border-end">
                    <div class="info mx-3">
                      <div class="info__title">EURO2012</div>
                      <div class="info__description">Чемпіонат Європи з футболу 2012</div>
                      <div class="info__task">
                        <div class="info__task-title">
                          <img src="@/assets/images/icon/portfolio-task.svg" alt="icon"><span>Завдання проекту</span>
                        </div>
                        <div class="info__task-description">
                            Проектування та створення онлайн платформи для відбору та тестування кандидатів на посаду стюардів для подальшого навчання, обслуговування та забезпечення безпеки стадіонів.
                        </div>
                      </div>
                      <div class="info__what">
                        <div class="info__what-title">
                          <img src="@/assets/images/icon/portfolio-what.svg" alt="icon"><span>Що зроблено?</span>
                        </div>
                        <div class="info__what-description">
                            Розроблено та розгорнуто платформу для реєстрації, ознайомлення з вимогами
                            та проведення тестування потенційних кандидатів.
                            Розроблено унікальний комплекс тестів та аналітик із залученим
                            професійним психологом для відбору максимально придатних кандидатів на роль стюарду.
                        </div>
                      </div>
                      <div class="info__link">
                        <img src="@/assets/images/icon/portfolio-link.svg" alt="icon">
                        <a href="https://en.wikipedia.org/wiki/UEFA_Euro_2012" target="_blank">UEFA EURO 2012</a>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-7 image">
                    <img src="@/assets/images/porfolio-euro.png" alt="image">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="swiper-slide">
            <div class="card mb-3">
              <div class="card-body">
                <div class="row align-items-center my-3">
                  <div class="col-lg-5 border-end">
                    <div class="info mx-3">
                      <div class="info__title">ART VIVACE Jewelry</div>
                      <div class="info__description">Ювелірний дім Art Vivace jewelry</div>
                      <div class="info__task">
                        <div class="info__task-title">
                          <img src="@/assets/images/icon/portfolio-task.svg" alt="icon"><span>Завдання проекту</span>
                        </div>
                        <div class="info__task-description">
                            Створення корпоративного сайту компанії для презентації ювелірних виробів власного виробництва,
                            просування та продажу на міжнародному ринку.
                        </div>
                      </div>
                      <div class="info__what">
                        <div class="info__what-title">
                          <img src="@/assets/images/icon/portfolio-what.svg" alt="icon"><span>Що зроблено?</span>
                        </div>
                        <div class="info__what-description">
                            З нуля і до останньої дрібниці створено дизайн у корпоративному стилі компанії.
                            Опрацьовано структуру сайту та каталогу продукції.
                            Реалізовано повний цикл від пошуку та замовлення до продажу ювелірних виробів власного виробництва на сайті компанії.
                        </div>
                      </div>
                      <div class="info__link">
                        <img src="@/assets/images/icon/portfolio-link.svg" alt="icon">
                        <a href="https://artvivace.com/" target="_blank">ART VIVACE Jewelry</a>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-7 image">
                    <img src="@/assets/images/portfolio-art.png" alt="image">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="swiper-slide">
            <div class="card mb-3">
              <div class="card-body">
                <div class="row align-items-center my-3">
                  <div class="col-lg-5 border-end">
                    <div class="info mx-3">
                      <div class="info__title">Шпількіна. Тренд взуття</div>
                      <div class="info__description">Дропшипінг оператор Шпількіна</div>
                      <div class="info__task">
                        <div class="info__task-title">
                          <img src="@/assets/images/icon/portfolio-task.svg" alt="icon"><span>Завдання проекту</span>
                        </div>
                        <div class="info__task-description">
                          Створення системи управління замовленнями та бронями взуття для забезпечення віддаленої роботи партнерів компанії.
                        </div>
                      </div>
                      <div class="info__what">
                        <div class="info__what-title">
                          <img src="@/assets/images/icon/portfolio-what.svg" alt="icon"><span>Що зроблено?</span>
                        </div>
                        <div class="info__what-description">
                            Спроектовано та реалізовано закриту систему управління замовленнями, що базується на принципах інтернет торгівлі.
                            Виконано комплекс інтеграцій із внутрішньою 1С системою.
                            Запущено систему повідомлень про внутрішньосистемні події на базі інтеграції з Telegram.
                        </div>
                      </div>
                      <div class="info__link d-none">
                        <img src="@/assets/images/icon/portfolio-link.svg" alt="icon">
                        <a href="#" target="_blank">Шпількіна</a>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-7 image">
                    <img src="@/assets/images/portfolio-shpilkina.png" alt="image">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="swiper-slide">
            <div class="card mb-3">
              <div class="card-body">
                <div class="row align-items-center my-3">
                  <div class="col-lg-5 border-end">
                    <div class="info mx-3">
                      <div class="info__title">NaRazbor</div>
                      <div class="info__description">Маркетплейс NaRazbor</div>
                      <div class="info__task">
                        <div class="info__task-title">
                          <img src="@/assets/images/icon/portfolio-task.svg" alt="icon"><span>Завдання проекту</span>
                        </div>
                        <div class="info__task-description">
                          Розробка мультивендорного маркетплейсу, що працює в ніші продажу вживаних запчастин.
                        </div>
                      </div>
                      <div class="info__what">
                        <div class="info__what-title">
                          <img src="@/assets/images/icon/portfolio-what.svg" alt="icon"><span>Що зроблено?</span>
                        </div>
                        <div class="info__what-description">
                            Спроектований, розроблений та запущений маркетплейс,
                            що дає можливість кожному опублікувати та продати свої вживані запчастини.
                            Розроблено спеціалізовану систему управління товарами.
                            Система зростає, розвивається та нарощує функціонал.
                        </div>
                      </div>
                      <div class="info__link">
                        <img src="@/assets/images/icon/portfolio-link.svg" alt="icon">
                        <a href="https://narazbor.com/" target="_blank">NaRazbor.com</a>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-7 image">
                    <img src="@/assets/images/portfolio-narazbor.png" alt="image">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- If we need pagination -->
        <div class="swiper-pagination"></div>
      </div>
    </div>
  </section>
  <section class="more-work">
    <div class="container">
      <div class="row">
        <div class="col-xxl-5 offset-xxl-1 col-lg-6 py-3 fromBottom">
          <img src="@/assets/images/more-work.png" alt="image" class="img-fluid mx-auto d-block">
        </div>
        <div class="col-xxl-5 col-lg-6 py-3 fromBottom">
          <h2>Бажаєте побачити більше робіт?</h2>
          <p>Залишіть заявку, і ми із задоволенням покажемо та розповімо, як ми реалізували понад 60 своїх успішних проектів.</p>
          <div class="buttons">
            <a href="#" @click.prevent="showApplication" class="btn btn-red">Залишити заявку</a>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="recommend" id="reviews">
    <div class="container">
      <div class="row">
        <div class="col-xxl-11 offset-xxl-1 fromBottom">
          <h2>Нас рекомендують</h2>
        </div>
        <div class="col-lg-4 fromBottom">
          <div class="card">
            <div class="card-body">
                Ми співпрацюємо вже понад десять років. Така співпраця давно перетворилася на міцне партнерство.
                За цей час ми змогли автоматизувати більшу частину своїх процесів та суттєво масштабувати бізнеси.
                Вся робота була зроблена під ключ, від формалізації ідеї до впровадження рішення та подальшої підтримки.
                Працювали разом за всіма нашими проектами. Рекомендую!
            </div>
          </div>
          <div class="info">
            <div class="info__image">
              <img src="@/assets/images/reviews-freeteam.jpg" alt="photo">
            </div>
            <div>
              <div class="info__name">Коваленко Павло</div>
              <div class="info__position">CEO FreeTeam, AnimaUnity, Skillonomy</div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 fromBottom">
          <div class="card">
            <div class="card-body">
                Нам потрібно було в найкоротший термін підібрати та навчити стюардів для обслуговування стадіонів України до Євро2012.
                Нам розробили систему відбору кандидатів із використанням комплексу тестів,
                спеціально підібраних для цього професійним психологом.
                Ця система дозволила нам як отримати якісний персонал вчасно, і навіть створити кадровий резерв.
            </div>
          </div>
          <div class="info">
            <div class="info__image">
              <img src="@/assets/images/reviews-euro.jpg" alt="photo">
            </div>
            <div>
              <div class="info__name">Дмитро Савицький</div>
              <div class="info__position">Керівник курсу підготовки стюардів</div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 fromBottom">
          <div class="card">
            <div class="card-body">
                Ми довго ламали голову як це все має виглядати та працювати.
                Адже важливо не просто викласти якісь фотографії виробів в інтернет, але донести їхню красу, вишукування, стиль та ексклюзивність.
                Також дуже важливо розуміти своїх клієнтів та завжди бути з ними на зв'язку. Всі ці питання були ретельно опрацьовані.
                Це дало можливість створити гідний ресурс, який нас репрезентує не лише в Україні, а й за її межами.
            </div>
          </div>
          <div class="info">
            <div class="info__image">
              <img src="@/assets/images/reviews-art.jpg" alt="photo">
            </div>
            <div>
              <div class="info__name">Петро Сірий</div>
              <div class="info__position">Власник ART VIVACE JEWELRY</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="how-work2">
    <div class="container">
      <div class="row">
        <div class="col-xxl-11 offset-xxl-1 fromBottom">
          <h2>Як ми працюємо?</h2>
        </div>
        <div class="col-lg-4 fromBottom mb-4">
          <div class="card">
            <div class="card-body">
              <span>01</span>
              <img src="@/assets/images/icon/phone-call.svg" alt="icon" class="img-fluid d-block">
              <div>
                <div class="card-title">Заявка</div>
                <div class="card-text">Просто залиште заявку на сайті або зв'яжіться з нами за телефоном.</div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 fromBottom mb-4">
          <div class="card">
            <div class="card-body">
              <span>02</span>
              <img src="@/assets/images/icon/web-browser.svg" alt="icon" class="img-fluid">
              <div>
                <div class="card-title">Робота</div>
                <div class="card-text">
                  З цієї миті ми вже почнемо роботи з Вашим проектом та доведемо його до кінця.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 fromBottom mb-4">
          <div class="card">
            <div class="card-body">
              <span>03</span>
              <img src="@/assets/images/icon/checklist.svg" alt="icon" class="img-fluid">
              <div>
                <div class="card-title">Результат</div>
                <div class="card-text">На кожному етапі у Вас будуті зрозумілі й відчутні результати.</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="discuss" id="contacts">
    <div class="container">
      <div class="row">
        <div class="col-xxl-5 offset-xxl-1 col-lg-6 fromBottom">
          <h2>Давайте обговоримо ваш проект</h2>
          <div class="discuss__item py-3">
            <div>
              <div class="discuss__item-name">Сергій</div>
              <a href="tel:+380952355556" class="discuss__item-phone">+38 (095) 235-55-56</a>
              <div class="discuss__item-soc">
                <a href="viber://chat?number=380952355556">
                  <img src="@/assets/images/icon/viber.svg" alt="icon" class="img-fluid">
                </a>
                <a href="https://t.me/SergiiMogylko">
                  <img src="@/assets/images/icon/telegram.svg" alt="icon" class="img-fluid">
                </a>
              </div>
            </div>
          </div>
          <div class="discuss__item">
            <div>
              <div class="discuss__item-name">Максим</div>
              <div class="discuss__item-soc">
                <a href="https://t.me/MaxBasmanov">
                  <img src="@/assets/images/icon/telegram.svg" alt="icon" class="img-fluid">
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xxl-5 col-lg-6 fromBottom">
          <form
            @submit.prevent="submitForm('Напишите нам')"
            class="card"
          >
            <div class="card-body">
              <div class="card-title">Напишіть нам</div>
              <div class="form-floating mb-3">
                <input type="text" v-model="form.name" class="form-control" id="inputName" placeholder="Ваше им'я">
                <label for="inputName">Ваше им'я</label>
              </div>
              <div class="form-floating mb-3">
                <input type="text" v-model="form.phone" class="form-control" id="inputPhone" placeholder="Телефон">
                <label for="inputPhone">Телефон</label>
              </div>
              <div class="form-floating mb-3">
                <input type="text" v-model="form.theme" class="form-control" id="inputTheme" placeholder="Тема">
                <label for="inputTheme">Тема</label>
              </div>
              <div class="form-floating mb-4">
                <textarea v-model="form.message" class="form-control" rows="4" id="inputMessage"
                          placeholder="Повідомлення">
                </textarea>
                <label for="inputMessage">Повідомлення</label>
              </div>
              <div class="text-center">
                <button type="submit" class="btn btn-red">Надіслати</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
  <footer class="footer">
    <div class="container">
      <div class="row">
        <div class="col-auto footer-item">Київ. Україна</div>
        <div class="col-auto footer-item">MaxCoding.Team &copy; 2023</div>
      </div>
    </div>
  </footer>
  <div class="form-application">
    <div class="form-application__overlay"></div>
    <div class="form-application__container">
      <form
        @submit.prevent="submitForm('Залишити заявку')"
        class="card"
      >
        <div class="card-body">
          <button @click="showApplication" type="button" class="card-close">
            <span></span>
            <span></span>
          </button>
          <div class="card-title">Залишити заявку</div>
          <div class="form-floating mb-3">
            <input type="text" v-model="form.name" class="form-control" id="inputName1" placeholder="Ваше им'я">
            <label for="inputName1">Ваше им'я</label>
          </div>
          <div class="form-floating mb-3">
            <input type="text" v-model="form.phone" class="form-control" id="inputPhone1" placeholder="Телефон">
            <label for="inputPhone1">Телефон</label>
          </div>
          <div class="form-floating mb-4">
            <textarea v-model="form.message" class="form-control" id="inputMessage1" rows="4" placeholder="Повідомлення">
            </textarea>
            <label for="inputMessage1">Повідомлення</label>
          </div>
          <div class="text-center">
            <button type="submit" class="btn btn-red">Надіслати</button>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="form-consultation">
    <div class="form-consultation__overlay"></div>
    <div class="form-consultation__container">
      <form
        @submit.prevent="submitForm('Отримати консультацію')"
        class="card"
      >
        <div class="card-body">
          <button @click="showConsultation" type="button" class="card-close">
            <span></span>
            <span></span>
          </button>
          <div class="card-title">Отримати консультацію</div>
          <div class="form-floating mb-3">
            <input type="text" v-model="form.name" class="form-control" id="inputName2" placeholder="Ваше им'я">
            <label for="inputName2">Ваше им'я</label>
          </div>
          <div class="form-floating mb-3">
            <input type="text" v-model="form.phone" class="form-control" id="inputPhone2" placeholder="Телефон">
            <label for="inputPhone2">Телефон</label>
          </div>
          <div class="form-floating mb-4">
            <textarea v-model="form.message" class="form-control" id="inputMessage2" rows="4" placeholder="Сообщение">
            </textarea>
            <label for="inputMessage2">Повідомлення</label>
          </div>
          <div class="text-center">
            <button type="submit" class="btn btn-red">Надіслати</button>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div v-if="this.success.show" class="alert alert-success alert-dismissible fade show mt-5" role="alert">
    {{ success.message }}
    <button @click="this.success.show = false" type="button" class="btn-close" data-bs-dismiss="alert"
            aria-label="Close"></button>
  </div>
  <div v-if="this.error.show" class="alert alert-danger alert-dismissible fade show mt-5" role="alert">
    {{ error.message }}
    <button @click="this.error.show = false" type="button" class="btn-close" data-bs-dismiss="alert"
            aria-label="Close"></button>
  </div>
</template>

<script>

import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
import { Swiper, Autoplay, Navigation, Pagination } from 'swiper'

Swiper.use([Autoplay, Navigation, Pagination])

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin)

export default {
  name: 'App',
  data () {
    return {
      menu: false,
      application: false,
      consultation: false,
      slider: null,
      form: {
        name: '',
        theme: '',
        phone: '',
        message: ''
      },
      success: {
        message: 'Ваше повідомлення відправлено!',
        show: false
      },
      error: {
        message: 'Введіть дані!',
        show: false
      }
    }
  },
  mounted () {
    this.slider = new Swiper('.swiper-container', {
      // Optional parameters
      direction: 'horizontal',
      loop: true,
      slidesPerView: 1,
      centeredSlides: true,
      speed: 500,
      autoplay: {
        delay: 4000
      },

      // If we need pagination
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      },

      // Navigation arrows
      navigation: {
        nextEl: '.swiper-button-nextc',
        prevEl: '.swiper-button-prevc'
      }
    })
    gsap.utils.toArray('.fromBottom').forEach(function (elem) {
      gsap.fromTo(elem,
        {
          y: '75px',
          alpha: 0
        },
        {
          duration: 1,
          y: 0,
          alpha: 1,
          scrollTrigger: {
            trigger: elem,
            start: 'top bottom'
          }
        })
    })
    this.tlMenu = gsap.timeline({ paused: true })
    this.tlMenu
      .to('.menu', { duration: 0, display: 'flex' })
      .fromTo('.menu .lang, .menu .nav-item, .menu .info > div', 0.3,
        {
          alpha: 0,
          x: gsap.utils.wrap(['-50%', '50%'])
        }, {
          alpha: 1,
          x: 0,
          stagger: 0.1
        })
      .to('.navbar-toggler span:nth-child(1)', {
        duration: 0.3,
        rotateZ: 43,
        translateY: '-3px',
        transformOrigin: 'top left'
      }, 0)
      .to('.navbar-toggler span:nth-child(2)', { duration: 0.3, alpha: 0 }, 0)
      .to('.navbar-toggler span:nth-child(3)', {
        duration: 0.3,
        rotateZ: -43,
        translateY: '3px',
        transformOrigin: 'top left'
      }, 0)

    this.tlApplication = gsap.timeline({ paused: true })
    this.tlApplication
      .to('.form-application', { duration: 0, display: 'flex' })
      .fromTo('.form-application .card', 0.3,
        {
          y: '-100%',
          alpha: 0
        }, {
          alpha: 1,
          y: 0
        })

    this.tlConsultation = gsap.timeline({ paused: true })
    this.tlConsultation
      .to('.form-consultation', { duration: 0, display: 'flex' })
      .fromTo('.form-consultation .card', 0.3,
        {
          y: '-100%',
          alpha: 0
        }, {
          alpha: 1,
          y: 0
        })

    // gsap.to('.image__rotate', { rotate: 360, duration: 60, repeat: -1, ease: 'none' })
    // gsap.to('.image__rotate .logo', { rotate: -360, duration: 60, repeat: -1, ease: 'none' })
  },
  methods: {
    scrollTo: function (target) {
      gsap.to(window, { scrollTo: target })
    },
    showMenu: function (target) {
      if (!this.menu) {
        document.querySelector('body').style.overflowY = 'hidden'
        this.tlMenu.play()
        this.menu = true
      } else {
        document.querySelector('body').style.overflowY = 'initial'
        this.tlMenu.reverse()
        gsap.to(window, { duration: 0.2, scrollTo: target, ease: 'power1.in' })
        this.menu = false
      }
    },
    showApplication: function (target) {
      if (!this.application) {
        document.querySelector('body').style.overflowY = 'hidden'
        this.tlApplication.play()
        this.application = true
      } else {
        document.querySelector('body').style.overflowY = 'initial'
        this.tlApplication.reverse()
        this.application = false
      }
    },
    showConsultation: function (target) {
      if (!this.consultation) {
        document.querySelector('body').style.overflowY = 'hidden'
        this.tlConsultation.play()
        this.consultation = true
      } else {
        document.querySelector('body').style.overflowY = 'initial'
        this.tlConsultation.reverse()
        this.consultation = false
      }
    },
    submitForm: function (form) {
      this.success.show = false
      this.error.show = false
      const formData = new FormData()
      formData.append('subject', 'Заявка з сайту')
      formData.append('form', form)
      formData.append('name', this.form.name)
      formData.append('phone', this.form.phone)
      formData.append('theme', this.form.theme)
      formData.append('message', this.form.message)

      if (this.form.name && this.form.phone && form && this.form.message) {
        fetch('/api/mail.php', {
          method: 'POST',
          body: formData
        })
          .then((response) => {
            return response.json()
          })
          .then((data) => {
            if (data.send) {
              this.success.show = true
              this.form.name = ''
              this.form.phone = ''
              this.form.theme = ''
              this.form.message = ''
            }
          })
      } else {
        this.error.show = true
      }
    }
  }
}
</script>

<style lang="scss" src="@/assets/scss/app.scss"></style>
